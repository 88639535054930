import { login, register, getUser, apiLogout, deleteAccount } from '../utils/apiRequests';

const ns = "auth";

export const LOGIN_USER_SUCCESS = `${ns}/LOGIN_USER_SUCCESS`;
export const LOGIN_USER_FAILURE = `${ns}/LOGIN_USER_FAILURE`;
export const LOGIN_USER_REQUEST = `${ns}/LOGIN_USER_REQUEST`;
export const LOGOUT_USER = `${ns}/LOGOUT_USER`;

export const REGISTER_USER_SUCCESS = `${ns}/REGISTER_USER_SUCCESS`;
export const REGISTER_USER_FAILURE = `${ns}/REGISTER_USER_FAILURE`;
export const REGISTER_USER_REQUEST = `${ns}/REGISTER_USER_REQUEST`;

export const GET_USER_LOAD = `${ns}/GET_USER_LOAD`;
export const GET_USER_SUCCESS = `${ns}/GET_USER_SUCCESS`;
export const GET_USER_FAIL = `${ns}/GET_USER_FAIL`;

export const DELETE_USER_REQUEST = `${ns}/DELETE_USER_REQUEST`;
export const DELETE_USER_SUCCESS = `${ns}/DELETE_USER_SUCCESS`;
export const DELETE_USER_FAILURE = `${ns}/DELETE_USER_FAILURE`;

// TODO: remove this?
function parseJSON(response) {
  return response.data;
}

export function loginUserSuccess(user, access) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      user,
      access,
    },
  };
}

export function loginUserFailure(error) {
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      statusText: error.response.statusText,
    },
  };
}

export function loginUserRequest() {
  return {
    type: LOGIN_USER_REQUEST,
  };
}

export function logout() {
  return dispatch => apiLogout()
    .then(() => {
      dispatch({ type: LOGOUT_USER });
    })
    .catch(() => {
      dispatch({ type: LOGOUT_USER });
    });
}

export function loginUser(email, password) {
  return function (dispatch) {
    dispatch(loginUserRequest());
    return login(email, password)
      .then(parseJSON)
      .then((response) => {
        if (response.result) {
          try {
            dispatch(loginUserSuccess(response.user, response.access));
          } catch (e) {
            dispatch(loginUserFailure({
              response: {
                status: 403,
                statusText: 'Login failed. Try again',
              },
            }));
          }
        } else {
          dispatch(loginUserFailure({
            response: {
              status: 403,
              statusText: 'Wrong User name or password, please try again',
            },
          }));
        }
      })
      .catch(() => {
        dispatch(loginUserFailure({
          response: {
            status: 403,
            statusText: 'Invalid username or password',
          },
        }));
      });
  };
}


export function registerUserRequest() {
  return {
    type: REGISTER_USER_REQUEST,
  };
}

export function registerUserSuccess(result) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: {
      user: result,
      statusText: 'you are successfully registered!',
    },
  };
}

export function registerUserFailure(error) {
  return {
    type: REGISTER_USER_FAILURE,
    payload: {
      status: error.response.status,
      statusText: error.response.data.statusText,
    },
  };
}

export function registerUser(email, password, accessToken) {
  return function (dispatch) {
    dispatch(registerUserRequest());
    return register(email, password, accessToken)
      .then((response) => {
        try {
          dispatch(registerUserSuccess(response));
        } catch (e) {
          dispatch(registerUserFailure({
            response: {
              status: 403,
              statusText: 'Invalid token',
            },
          }));
        }
      })
      .catch((error) => {
        dispatch(registerUserFailure(error));
      });
  };
}

export function tryGetUser() {
  return (dispatch) => {
    dispatch({ type: GET_USER_LOAD });
    return getUser()
      .then((result) => {
        dispatch({ type: GET_USER_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_USER_FAIL, payload: error });
      });
  };
}

export function deleteUserSuccess() {
  return {
    type: DELETE_USER_SUCCESS,
  };
}

export function deleteUserFailure(error) {
  return {
    type: DELETE_USER_FAILURE,
    payload: {
      response: error.response,
    },
  };
}

export function deleteUserRequest() {
  return {
    type: DELETE_USER_REQUEST,
  };
}

export function deleteUser() {
  return function (dispatch) {
    dispatch(deleteUserRequest());
    return deleteAccount()
      .then(parseJSON)
      .then((response) => {
        if (response.result) {
          try {
            dispatch(deleteUserSuccess());
          } catch (e) {
            dispatch(deleteUserFailure({ response }));
          }
        } else {
          dispatch(deleteUserFailure({ response }));
        }
      })
      .catch((e) => {
        dispatch(deleteUserFailure({ e }));
      });
  };
}
