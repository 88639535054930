import { API_URL } from "../constants/index";

import axios from "axios";

export function register(email, password, accessToken) {
  return axios.post(`${API_URL}api/register`, {
    email,
    password,
    accessToken,
  });
}

export function login(email, password) {
  return axios.post(`${API_URL}api/login`, {
    email,
    password,
  });
}

export function getUser() {
  return axios.get(`${API_URL}api/confirm_auth`);
}

export function apiLogout() {
  return axios.get(`${API_URL}api/logout`);
}

export function deleteAccount() {
  return axios.get(`${API_URL}api/delete_acct`);
}

export function emailResetPassword(email) {
  return axios.post(`${API_URL}api/email_reset_password`, {
    email,
  });
}

export function updatePassword(token, password) {
  return axios.post(`${API_URL}api/reset_password`, {
    token,
    password,
  });
}

export function fetchStoresList() {
  return axios.get(`${API_URL}api/stores`);
}

export function postInventoryOut({ item, spreadSheetId }) {
  return axios.post(`${API_URL}api/inventory/out`, {
    item,
    google_sheet_id: spreadSheetId,
  });
}

export function postInventoryResetAll(spreadSheetId) {
  return axios.post(`${API_URL}api/inventory/reset_all`, {
    google_sheet_id: spreadSheetId,
  });
}

export function postInventoryReset({ item, spreadSheetId }) {
  return axios.post(`${API_URL}api/inventory/reset_item`, {
    item,
    google_sheet_id: spreadSheetId,
  });
}

export function getSearchItems(searchQuery) {
  return axios.get(`${API_URL}api/product/search_items?q=${searchQuery}`);
}

export function getSearchUrl(searchQuery) {
  return `${API_URL}api/product/search_items?q=${searchQuery}`;
}

// Create a new vendor
export const createVendor = async ({ name }) => {
  const response = await axios.post(`${API_URL}api/product/vendor`, {
    name,
  });
  return response.data;
};

export const renameVendor = async ({ vendorId, name }) => {
  const response = await axios.put(`${API_URL}api/product/vendor/${vendorId}`, {
    name,
  });
  return response.data;
};

export const archiveVendor = async (vendorId, archive) => {
  const response = await axios.put(
    `${API_URL}api/product/vendor/${vendorId}/archive`,
    {
      archive,
    }
  );
  return response.data;
};

export const getStoresVendorsProducts = async (storeId) => {
  const response = await axios.get(
    `${API_URL}api/product/store/${storeId}/vendors_products`
  );
  return response.data;
};

// Get the list of vendors
export const getVendorsList = async () => {
  const response = await axios.get(`${API_URL}api/product/vendors`);
  return response.data;
};

// Add a new product
export const addProduct = async ({ vendorId, name }) => {
  const response = await axios.post(`${API_URL}api/product/product`, {
    vendor_id: vendorId,
    name,
  });
  return response.data;
};

// add square item to product
export const addSquareItem = async ({
  productId,
  squareItemId,
  parentItemId,
}) => {
  const response = await axios.post(
    `${API_URL}api/product/product/${productId}/square_item`,
    {
      item_id: squareItemId,
      parent_item_id: parentItemId,
    }
  );
  return response.data;
};

export const removeSquareItem = async (productId, squareItemId) => {
  const response = await axios.delete(
    `${API_URL}api/product/product/${productId}/square_item/${squareItemId}`
  );
  return response.data;
};

export const getStores = async () => {
  const response = await axios.get(`${API_URL}api/stores`);
  return response.data;
};

export const getAnalytics = async () => {
  const response = await axios.get(`${API_URL}api/analytics/recent`);
  return response.data;
};

export const getLeftoverHistory = async () => {
  const response = await axios.get(`${API_URL}api/analytics/inventory`);
  return response.data;
};

export const updateProductStores = async (productId, availableInStoresIds) => {
  try {
    await axios.put(`/api/product/product/${productId}`, {
      available_in_stores_ids: availableInStoresIds,
    });
  } catch (error) {
    console.error("Error updating product stores:", error);
  }
};

export const updateProductName = async ({ productId, name }) => {
  try {
    await axios.put(`/api/product/product/${productId}`, {
      name,
    });
  } catch (error) {
    console.error("Error updating product stores:", error);
  }
};

export const updateProductArchive = async (productId, archive) => {
  try {
    await axios.put(`/api/product/product/${productId}/archive`, {
      archive,
    });
  } catch (error) {
    console.error("Error updating product stores:", error);
  }
};

export const updateLeftovers = async ({ googleSheetId, items }) => {
  try {
    await axios.post(`/api/inventory/leftovers`, {
      google_sheet_id: googleSheetId,
      items,
    });
  } catch (error) {
    console.error("Error updating product leftovers:", error);
    throw error; // Rethrow to handle it in the component
  }
};

export const getLeftovers = async (googleSheetId) => {
  try {
    const response = await axios.get(
      `/api/inventory/leftovers?google_sheet_id=${googleSheetId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting product leftovers:", error);
    throw error; // Rethrow to handle it in the component
  }
};
