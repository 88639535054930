import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { addProduct, updateProductName } from "../../utils/apiRequests";

const ProductModal = ({
  open,
  onClose,
  type,
  defaultName,
  vendor,
  product,
}) => {
  const [name, setName] = useState(defaultName || "");
  const queryClient = useQueryClient();

  const addProductMutation = useMutation(addProduct, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      // Invalidate the vendor query cache after adding the product
      queryClient.invalidateQueries("vendors");
    },
  });

  const renameProductMutation = useMutation(updateProductName, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      // Invalidate the vendor query cache after adding the product
      queryClient.invalidateQueries("vendors");
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (type === "create") {
      addProductMutation.mutate({ vendorId: vendor.id, name });
    } else {
      renameProductMutation.mutate({ productId: product.id, name });
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {type === "create"
          ? `Create Product for ${vendor.name}`
          : "Rename Product"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            label="Product Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">
              {type === "create" ? "Create" : "Rename"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

ProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["create", "rename"]).isRequired,
  defaultName: PropTypes.string,
  vendor: PropTypes.object,
  product: PropTypes.object,
};

export default ProductModal;
