import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Grid from "@mui/material/Unstable_Grid2";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import { getStoresList } from "../../actions/stores";

const StoreList = ({ stores, auth, dispatch }) => {
  const storeList = stores.stores || [];

  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1">
          Stores
        </Typography>
      </Grid>
      <Grid>
        <Grid xs={12}>
          {!stores.stores && <CircularProgress sx={{ m: 2 }} />}
          {!stores.isFetching && !stores.isSuccess && (
            <Alert severity="error">
              Oops, something went wrong. Try again.
            </Alert>
          )}
          <Grid sm={6} xs={12}>
            {storeList.map((store) => (
              <Box m={2} key={store.name}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography variant="h5">{`${store.name}`}</Typography>
                    <CardActions>
                      <Button
                        href={`/out/${store.id}?key=${auth.access.google.key}&client=${auth.access.google.client}&sheetId=${store.sheet_id}`}
                      >
                        Go to PARticle :: Out
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    stores: state.stores,
  };
}

StoreList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(StoreList);
