import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useQueryClient } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import {
  Alert,
  Divider,
  Paper,
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import AutocompleteSearch from "./AutocompleteSearch";
import SquareItemChip from "./SquareItemChip";

import {
  removeSquareItem,
  updateProductArchive,
  updateProductStores,
} from "../../utils/apiRequests";

import ProductModal from "./ProductModal";

const VendorProduct = ({ product, stores }) => {
  const {
    name,
    square_items: squareItems,
    available_in_stores: availableInStores,
  } = product;
  const queryClient = useQueryClient();
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [enabledStores, setEnabledStores] = useState(
    stores.reduce(
      (acc, store) => ({
        ...acc,
        [store.id]: availableInStores.some(({ id }) => id === store.id),
      }),
      {}
    )
  );

  const handleStoreToggle = (store) => {
    setEnabledStores((prevState) => {
      const newEnabledStores = {
        ...prevState,
        [store.id]: !prevState[store.id],
      };

      const availableInStoresIds = Object.entries(newEnabledStores)
        .filter(([_, isEnabled]) => isEnabled)
        .map(([storeId, _]) => parseInt(storeId, 10));

      updateProductStores(product.id, availableInStoresIds);
      return newEnabledStores;
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameProduct = () => {
    setIsProductModalOpen(true);
    handleMenuClose();
  };

  const handleCloseProductModal = () => {
    setIsProductModalOpen(false);
    handleMenuClose();
  };

  const handleArchiveProduct = () => {
    updateProductArchive(product.id, true).then(() => {
      queryClient.invalidateQueries("vendors");
    });
    handleMenuClose();
  };

  const handleDeleteSquareItem = (squareItem) => {
    removeSquareItem(product.id, squareItem.id).then(() => {
      queryClient.invalidateQueries("vendors");
    });
  };

  return (
    <Paper sx={{ p: 1, mb: 2 }}>
      <ProductModal
        open={isProductModalOpen}
        onClose={handleCloseProductModal}
        type="rename"
        product={product}
        defaultName={name}
      />
      <ListItem alignItems="flex-start">
        <ListItemText>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography component="h1" variant="h7" color="text.primary">
                {name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleRenameProduct}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  Rename
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleArchiveProduct}>
                  <ListItemIcon>
                    <ArchiveIcon fontSize="small" />
                  </ListItemIcon>
                  Archive
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item md={6} xs={12}>
              <AutocompleteSearch product={product} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {stores.map((store) => (
                <Grid item key={store}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">{store.name}</Typography>
                    <Switch
                      checked={enabledStores[store.id]}
                      onChange={() => handleStoreToggle(store)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
            {squareItems.map((item) => (
              <SquareItemChip
                key={item.item_id}
                item={item}
                onDelete={handleDeleteSquareItem}
              />
            ))}
          </Grid>
          {!squareItems.length && (
            <Alert severity="info">No Square Items</Alert>
          )}
        </ListItemText>
      </ListItem>
    </Paper>
  );
};

VendorProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    inventory: state.inventory,
  };
}

export default connect(mapStateToProps)(VendorProduct);
