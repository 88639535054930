import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import { VendorAvatar } from "../VendorAvatar";

const ArchivedVendorCard = ({ vendor, onUnarchive }) => {
  const { products } = vendor;

  return (
    <Card sx={{ minWidth: 275, mt: 2, mb: 2, backgroundColor: "#f5f5f5" }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <VendorAvatar vendor={vendor} />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={1} mt={1}>
          {products.map((product) => (
            <Grid item key={product.id}>
              <Typography variant="body2">{product.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button size="small" onClick={() => onUnarchive(vendor)}>
          Unarchive
        </Button>
      </CardActions>
    </Card>
  );
};

export default ArchivedVendorCard;
