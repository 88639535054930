import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import { Alert, Button, CircularProgress, TextField } from "@mui/material";

import { connect } from "react-redux";
import { capitalize } from "./utils";

import { inventoryAllReset } from "../../actions/inventory";
import { VendorAvatar } from "../VendorAvatar";
import { getLeftovers, updateLeftovers } from "../../utils/apiRequests";

const LeftOvers = ({ vendors, apiConfig, dispatch }) => {
  const [inventory, setInventory] = useState({});
  const [recorded, setRecorded] = useState(false);
  const [alert, setAlert] = useState("");

  const { data: outTimes } = useQuery("leftovers", () =>
    getLeftovers(apiConfig.spreadSheetId)
  );

  const mutation = useMutation(updateLeftovers, {
    onSuccess: () => {
      setAlert("Recorded! Thanks!");
    },
    onError: () => {
      setAlert("Error recording leftovers. Please try again.");
    },
  });

  const inputChanged = (vendor, item, amount) => {
    setInventory((prevInventory) => {
      const updatedInventory = { ...prevInventory };
      if (!updatedInventory[vendor.name]) {
        updatedInventory[vendor.name] = {};
      }
      updatedInventory[vendor.name][item.name] = amount;
      return updatedInventory;
    });
  };

  const recordToParticle = () => {
    const items = [];
    for (let vendor in inventory) {
      const itemToCount = inventory[vendor];
      for (let item in itemToCount) {
        const count = itemToCount[item];
        if (count.length > 0) {
          const vendorObject = vendors.find((v) => v.name === vendor);
          const itemObject = vendorObject.products.find((i) => i.name === item);
          items.push({ item: itemObject.id, leftovers: parseInt(count) });
        }
      }
    }

    mutation.mutate({ googleSheetId: apiConfig.spreadSheetId, items });
  };

  const record = () => {
    recordToParticle();
    resetInventory();
    setRecorded(true);
  };

  const resetInventory = () => {
    const { spreadSheetId } = apiConfig;
    dispatch(inventoryAllReset(spreadSheetId));
  };

  if (vendors) {
    return (
      <Grid container>
        {alert && <Alert severity="warning">{alert}</Alert>}
        <Grid xs={12}>
          <Alert severity="info">Enter number of remaining items.</Alert>
        </Grid>
        {vendors.map((vendor) => {
          return (
            <Grid xs={12} key={vendor.id}>
              <Grid xs={12}>
                <VendorAvatar vendor={vendor} />
              </Grid>
              <Grid container spacing={1}>
                {vendor.products.map((item) => {
                  return (
                    <Grid xs={12} key={`${vendor.id}-${item.id}-leftover`}>
                      <TextField
                        fullWidth
                        label={`${capitalize(item.name)} ${
                          outTimes && outTimes[item.id]?.leftovers === 0
                            ? " (Out)"
                            : "Remaining"
                        }`}
                        type="number"
                        disabled={
                          outTimes && outTimes[item.id]?.leftovers === 0
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          inputChanged(vendor, item, e.target.value)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
        <Grid xs={12}>
          <>
            {recorded && <Alert severity="success"> Recorded! Thanks! </Alert>}
            {!recorded && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={mutation.isLoading}
                startIcon={
                  mutation.isLoading ? <CircularProgress size={12} /> : null
                }
                onClick={record}
              >
                Record Leftovers
              </Button>
            )}
          </>
        </Grid>
      </Grid>
    );
  } else {
    return <Alert severity="info">Loading vendor list...</Alert>;
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(LeftOvers);
