import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Alert, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { loginUser } from "../../actions/auth";

import "./styles.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  handleInputChange = (e, inputField) => {
    this.setState({
      [inputField]: e.target.value,
    });
  };

  handleKeyPress(e) {
    if (e.key === "Enter") {
      if (!this.state.disabled) {
        this.checkAndLogin(e);
      }
    }
  }

  checkAndLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    // TODO: do some error checking
    // const canProceed = validateEmail(this.state.email) && this.passwordRef.isValid();
    const canProceed = true;

    if (canProceed) {
      this.props.dispatch(loginUser(email, password));
    } else {
      // TODO: error checking
      // this.emailRef.isValid();
      // this.passwordRef.isValid();
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <>
        {this.props.auth.statusText && (
          <Alert severity="error">{this.props.auth.statusText}</Alert>
        )}
        <Grid container spacing={2} xs={12} className="login">
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e, "email")}
              name="email"
              id="email"
              placeholder="Enter your email"
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Password"
              value={this.state.password}
              onChange={(e) => this.handleInputChange(e, "password")}
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
            />
          </Grid>
          <Grid xs={12}>
            <Button fullWidth variant="contained" onClick={this.checkAndLogin}>
              Login
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    isAuthenticating: state.auth.isAuthenticating,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps)(Login);
