import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { getSearchUrl, addSquareItem } from "../../utils/apiRequests";

const AutocompleteSearch = ({ product }) => {
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 200);
  const { data: searchItemsData } = useQuery(
    ["autocompleteSearchItems", debouncedSearchValue],
    async () => {
      const response = await fetch(getSearchUrl(debouncedSearchValue));
      const data = await response.json();
      return data.items;
    },
    { enabled: debouncedSearchValue.length >= 3 }
  );

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value.trim());
  };

  const addSquareItemMutation = useMutation(addSquareItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["vendors"]);
      setSearchValue("");
    },
  });

  const handleSearchItemSelect = (event, value) => {
    if (value) {
      addSquareItemMutation.mutate({
        productId: product.id,
        squareItemId: value.item_id,
        parentItemId: value.parent_item_id,
      });
      setSearchValue("");
    }
  };

  const autocompleteOptions = useMemo(
    () => (searchItemsData ? searchItemsData : []),
    [searchItemsData]
  );

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={(option) => option.name}
      options={autocompleteOptions}
      loading={!searchItemsData}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add item"
          margin="normal"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchInputChange}
        />
      )}
      onChange={handleSearchItemSelect}
    />
  );
};

AutocompleteSearch.propTypes = {
  product: PropTypes.object.isRequired,
};

export default AutocompleteSearch;
