import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { createVendor, renameVendor } from "../../utils/apiRequests";

const VendorModal = ({ open, onClose, type, defaultName, vendor }) => {
  const [name, setName] = useState(defaultName || "");
  const queryClient = useQueryClient();

  const createVendorMutation = useMutation(createVendor, {
    onSuccess: (data) => {
      onClose(data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries("vendors");
    },
  });

  const renameVendorMutation = useMutation(renameVendor, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries("vendors");
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (type === "create") {
      createVendorMutation.mutate({ name });
    } else {
      renameVendorMutation.mutate({ vendorId: vendor.id, name });
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {type === "create" ? "Create Vendor" : "Rename Vendor"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            label="Vendor Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">
              {type === "create" ? "Create" : "Rename"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

VendorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["create", "rename"]).isRequired,
  defaultName: PropTypes.string,
  vendor: PropTypes.object,
};

export default VendorModal;
