import React, { useState } from "react";

import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useMutation, useQueryClient } from "react-query";

import { postInventoryOut, postInventoryReset } from "../../utils/apiRequests";

const OutButton = ({ item, outTimes, apiConfig }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const inventoryOutMutation = useMutation(postInventoryOut, {
    onSuccess: () => {
      queryClient.invalidateQueries("leftovers");
    },
  });
  const inventoryResetMutation = useMutation(postInventoryReset, {
    onSuccess: () => {
      queryClient.invalidateQueries("leftovers");
    },
  });

  const handleOut = (item) => {
    recordSquareOut(item);
  };

  const recordSquareOut = (item) => {
    const { spreadSheetId } = apiConfig;
    inventoryOutMutation.mutate({ item: item.id, spreadSheetId });
  };

  const handleReset = (item) => {
    resetSquareOut(item);
  };

  const resetSquareOut = (item) => {
    const { spreadSheetId } = apiConfig;
    inventoryResetMutation.mutate({ item: item.id, spreadSheetId });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      {outTimes && outTimes[item.id]?.leftovers === 0 ? (
        <Button
          variant="outlined"
          color="secondary"
          disabled={inventoryResetMutation.isLoading}
          startIcon={
            inventoryResetMutation.isLoading ? (
              <CircularProgress size={12} />
            ) : null
          }
          fullWidth
          onClick={() => toggleModal()}
        >
          {item.name}
          {` | ${new Date(
            `${outTimes[item.id].date}Z`
          ).toLocaleTimeString()}  👈🏻`}
        </Button>
      ) : (
        <Button
          variant="outlined"
          fullWidth
          disabled={inventoryOutMutation.isLoading}
          startIcon={
            inventoryOutMutation.isLoading ? (
              <CircularProgress size={12} />
            ) : null
          }
          onClick={() => handleOut(item)}
        >
          {item.name}{" "}
        </Button>
      )}

      <Dialog open={modalOpen} onClose={toggleModal}>
        <DialogTitle>Confirm Undo</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Are you sure you want to restock "${item.name}"?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleReset(item);
              toggleModal();
            }}
          >
            Confirm
          </Button>{" "}
          <Button onClick={toggleModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OutButton;
