import { postInventoryResetAll, getSearchItems } from "../utils/apiRequests";

const namespace = "inventory";

export const INVENTORY_RESET_ALL_REQUEST = `${namespace}/INVENTORY_RESET_ALL_REQUEST`;
export const INVENTORY_RESET_ALL_FAILURE = `${namespace}/INVENTORY_RESET_ALL_FAILURE`;
export const INVENTORY_RESET_ALL_SUCCESS = `${namespace}/INVENTORY_RESET_ALL_SUCCESS`;

export const INVENTORY_SEARCH_ITEMS_REQUEST = `${namespace}/SEARCH_ITEMS_REQUEST`;
export const INVENTORY_SEARCH_ITEMS_FAILURE = `${namespace}/SEARCH_ITEMS_FAILURE`;
export const INVENTORY_SEARCH_ITEMS_SUCCESS = `${namespace}/SEARCH_ITEMS_SUCCESS`;

const DEFAULT_ERROR = {
  response: {
    status: 500,
    statusText: "Something went wrong. Try again.",
  },
};

export function inventoryResetAllRequest() {
  return {
    type: INVENTORY_RESET_ALL_REQUEST,
  };
}

export function inventoryResetAllFailure(error = DEFAULT_ERROR) {
  return {
    type: INVENTORY_RESET_ALL_FAILURE,
    payload: {
      statusText: error.response.statusText,
    },
  };
}

export function inventoryResetAllSuccess() {
  return {
    type: INVENTORY_RESET_ALL_SUCCESS,
    payload: {
      status: 200,
      statusText: "inventory successfully reset.",
    },
  };
}

export function inventoryAllReset(spreadSheetId) {
  return (dispatch) => {
    dispatch(inventoryResetAllRequest());
    return postInventoryResetAll(spreadSheetId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(inventoryResetAllSuccess(response.data));
        } else {
          dispatch(inventoryResetAllFailure());
        }
      })
      .catch((e) => {
        dispatch(inventoryResetAllFailure());
      });
  };
}

export function searchItemsRequest() {
  return {
    type: INVENTORY_SEARCH_ITEMS_REQUEST,
  };
}

export function searchItemsFailure(error = DEFAULT_ERROR) {
  return {
    type: INVENTORY_SEARCH_ITEMS_FAILURE,
    payload: {
      statusText: error.response.statusText,
    },
  };
}

export function searchItemsSuccess(items) {
  return {
    type: INVENTORY_SEARCH_ITEMS_SUCCESS,
    payload: {
      status: 200,
      statusText: "searched items successfully retrieved.",
      items,
    },
  };
}

export function searchItems(query) {
  return (dispatch) => {
    dispatch(searchItemsRequest());
    return getSearchItems(query)
      .then((response) => {
        if (response.status === 200) {
          dispatch(searchItemsSuccess(response.data));
        } else {
          dispatch(searchItemsFailure());
        }
      })
      .catch((e) => {
        dispatch(searchItemsFailure());
      });
  };
}
