import { Typography } from "@mui/material";
import React from "react";

export function VendorAvatar({ vendor }) {
  return (
    <Typography variant="h6" key={vendor.id}>
      <img
        src={`https://robohash.org/${vendor.id}.png?size=40x40`}
        alt={vendor.name}
      />{" "}
      <span style={{ verticalAlign: "bottom" }}>{vendor.name}</span>
    </Typography>
  );
}
