import React from "react";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import { Alert, Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  getVendorsList,
  getStores,
  archiveVendor,
} from "../../utils/apiRequests";
import VendorList from "./VendorList";
import CreateVendorButton from "./CreateVendorButton";
import ArchivedVendorCard from "./ArchivedVendorCard";

const Vendors = () => {
  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingVendors,
    isError: isErrorVendors,
    data: vendors,
  } = useQuery("vendors", getVendorsList);
  const {
    isLoading: isLoadingStores,
    isError: isErrorStores,
    data: stores,
  } = useQuery("stores", getStores);

  // order vendors by id
  vendors?.sort((a, b) => a.id - b.id);
  const activeVendors = vendors?.filter((vendor) => !vendor.archived) || [];
  const archivedVendors = vendors?.filter((vendor) => vendor.archived) || [];

  const handleUnarchiveVendor = (vendor) => {
    archiveVendor(vendor.id, false).then(() => {
      queryClient.invalidateQueries("vendors");
    });
  };

  return (
    <Grid xs={12} sx={{ mb: 5 }}>
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1">
          PARticle Items to Square Items
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="text.secondary"
          sx={{ my: 1 }}
        >
          The following items will be 86ed/reset on Square when PARticle buttons
          are pressed.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Grid xs={12}>
          {
            // loading data
            isLoadingStores && isLoadingVendors && (
              <CircularProgress sx={{ m: 2 }} />
            )
          }
          {
            // error fetching vendors
            (isErrorVendors || isErrorStores) && (
              <Alert security="error">
                Oops, something went wrong. Try again.
              </Alert>
            )
          }
          <Box pt={1} pb={4}>
            <CreateVendorButton />
          </Box>
          {activeVendors.map((vendor) => (
            <Box sx={{ mb: 3 }} key={vendor.id}>
              <VendorList vendor={vendor} stores={stores} />
            </Box>
          ))}
          {archivedVendors.length > 0 && (
            <>
              <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                Archived Vendors
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                These vendors are archived and will not be synced to Square.
              </Typography>
              <Grid container spacing={2}>
                {archivedVendors.map((vendor) => (
                  <Grid key={vendor.id}>
                    <ArchivedVendorCard
                      vendor={vendor}
                      onUnarchive={handleUnarchiveVendor}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Vendors.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Vendors;
