import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";

import { stringAvatar } from "./utils";

const ArchivedProductCard = ({ product, onUnarchive }) => {
  const { name, square_items: squareItems } = product;

  return (
    <Card sx={{ minWidth: 275, mt: 2, mb: 2, backgroundColor: "#f5f5f5" }}>
      <CardContent>
        <Typography variant="h6" component="h2">
          {name}
        </Typography>
        <Grid container spacing={1} direction="column">
          {squareItems.map((item) => (
            <Grid item key={item.item_id}>
              <Chip
                label={item.name}
                color={`${
                  item.name.includes("not found") ? "error" : "default"
                }`}
                avatar={
                  <Avatar {...stringAvatar(item.name.split(" | ")[0])}>
                    {item.name[0]}
                  </Avatar>
                }
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button size="small" onClick={() => onUnarchive(product)}>
          Unarchive
        </Button>
      </CardActions>
    </Card>
  );
};

export default ArchivedProductCard;
