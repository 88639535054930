import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

import { getAnalytics, getLeftoverHistory } from "../../utils/apiRequests";

const getWeekRange = (date) => {
  const startOfWeek = new Date(date);
  const day = startOfWeek.getDay();
  const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  startOfWeek.setDate(diff);
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
};

const getWeatherEmoji = (weather) => {
  const weatherStatusEmojis = {
    Snow: "❄️",
    Haze: "🌫️",
    Mist: "🌫️",
    Smoke: "💨",
    Fog: "😶‍🌫️",
    Drizzle: "🌧️",
    Clear: "☀️",
    Thunderstorm: "⛈️",
    Rain: "🌧️",
    Clouds: "☁️",
  };

  const weatherDetailEmojis = {
    "broken clouds": "🌥️",
    "few clouds": "🌤️",
    "scattered clouds": "🌤️",
  };
  return (
    weatherDetailEmojis[weather.weather_detail] ||
    weatherStatusEmojis[weather.weather_status]
  );
};

const handleDownloadInventory = async (storeName, weekRange, inventory) => {
  if (inventory) {
    const { startOfWeek, endOfWeek } = weekRange;

    const filteredInventory = inventory.filter((item) => {
      const itemDate = new Date(item.date + "Z");
      return (
        item.store_name === storeName &&
        itemDate >= startOfWeek &&
        itemDate <= endOfWeek
      );
    });

    const csv = filteredInventory.map((item) => {
      const timestampUTC = `${item.date}Z`;
      const date = new Date(timestampUTC);
      const line = `${item.store_name},${item.vendor_name},${
        item.product_name
      }, ${date.toLocaleDateString(undefined, {
        weekday: "long",
      })},${date.toLocaleDateString()},${date.toLocaleTimeString()},${
        item.leftovers
      }`;
      return line.split("\n").join("");
    });

    csv.unshift("Store,Vendor,Item,Day,Date,Time Out,Leftovers");

    const csvData = csv.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `inventory-history-${storeName}-${
      startOfWeek.toISOString().split("T")[0]
    }.csv`;
    a.click();
  }
};

const Analytics = () => {
  const {
    isLoading: isLoadingAnalytics,
    isError: isErrorAnalytics,
    data: analytics,
  } = useQuery("recentAnalytics", getAnalytics);

  const {
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    data: inventory,
  } = useQuery("inventoryHistory", getLeftoverHistory);

  const getPastWeeks = (numWeeks) => {
    const weeks = [];
    for (let i = 0; i < numWeeks; i++) {
      const today = new Date();
      const pastWeek = new Date(today);
      pastWeek.setDate(today.getDate() - i * 7);
      weeks.push(getWeekRange(pastWeek));
    }
    return weeks;
  };

  const pastWeeks = getPastWeeks(4);
  const uniqueStores = [...new Set(inventory?.map((item) => item.store_name))];

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStore, setCurrentStore] = useState(null);

  const handleMenuClick = (event, store) => {
    setAnchorEl(event.currentTarget);
    setCurrentStore(store);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentStore(null);
  };

  return (
    <Grid xs={12} sx={{ mb: 5 }}>
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1">
          Analytics
        </Typography>
      </Grid>
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2">
          Inventory History
        </Typography>
      </Grid>

      <Grid xs={12} sx={{ mb: 2 }}>
        {isLoadingInventory && <Typography>Loading...</Typography>}
        {isErrorInventory && <Typography>Error loading inventory</Typography>}
        {uniqueStores?.map((store) => (
          <Grid xs={12} sx={{ mb: 1 }} key={store}>
            <ButtonGroup variant="contained" color="primary">
              <Button>{store}</Button>
              <Button
                color="primary"
                size="small"
                aria-controls="split-button-menu"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                aria-label="select week"
                aria-haspopup="menu"
                onClick={(e) => handleMenuClick(e, store)}
              >
                ▼
              </Button>
            </ButtonGroup>
            <Menu
              id="split-button-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && currentStore === store}
              onClose={handleMenuClose}
            >
              {pastWeeks.map((week, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleDownloadInventory(store, week, inventory);
                    handleMenuClose();
                  }}
                >
                  {index === 0
                    ? `Current Week (Incomplete)`
                    : `${week.startOfWeek.toLocaleDateString()} - ${week.endOfWeek.toLocaleDateString()}`}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ))}
      </Grid>

      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2">
          Weather History
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="text.secondary"
          sx={{ my: 1 }}
        >
          Here's the weather history from the past few weeks. More coming soon!
        </Typography>
      </Grid>

      {isLoadingAnalytics && <Typography>Loading...</Typography>}
      {isErrorAnalytics && <Typography>Error loading analytics</Typography>}
      {analytics && (
        <Paper elevation={3}>
          <List>
            {analytics.map((analytic) => (
              <div key={analytic.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {new Date(analytic.created_at).toLocaleDateString(
                              undefined,
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item md={1} xs={2}>
                          <Typography variant="h4">
                            {analytic.temp
                              ? `${Math.round(analytic.temp)}°`
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid item md={11} xs={10}>
                          <Typography variant="h4" component="div">
                            {analytic.weather_status
                              ? getWeatherEmoji(analytic)
                              : ""}{" "}
                            {analytic.weather_status ?? "--"}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {analytic.weather_detail ?? "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Box sx={{ my: 2 }}>
                  <Divider variant="inset" component="li" />
                </Box>
              </div>
            ))}
          </List>
        </Paper>
      )}
    </Grid>
  );
};

Analytics.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Analytics;
