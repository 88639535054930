import React, { useState } from "react";

import { Box, List } from "@mui/material";
import { useQueryClient } from "react-query";

import { VendorAvatar } from "../VendorAvatar";
import ProductModal from "./ProductModal";
import VendorModal from "./VendorModal";
import { archiveVendor, updateProductArchive } from "../../utils/apiRequests";
import VendorMenu from "./VendorMenu";
import EmptyVendor from "./EmptyVendor";
import PopulatedVendor from "./PopulatedVendor";

const VendorList = ({ vendor, stores }) => {
  const { name, products } = vendor;
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const queryClient = useQueryClient();

  // order the product by id
  products.sort((a, b) => a.id - b.id);
  const activeProducts = products.filter((product) => !product.archived);
  const archivedProducts = products.filter((product) => product.archived);

  const handleRenameVendor = () => {
    setIsVendorModalOpen(true);
  };

  const handleCloseVendorModal = () => {
    setIsVendorModalOpen(false);
  };

  const handleArchiveVendor = () => {
    archiveVendor(vendor.id, true).then(() => {
      queryClient.invalidateQueries("vendors");
    });
  };

  const handleAddProduct = () => {
    setIsProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setIsProductModalOpen(false);
  };

  const handleUnarchiveProduct = (product) => {
    updateProductArchive(product.id, false).then(() => {
      queryClient.invalidateQueries("vendors");
    });
  };

  return (
    <Box sx={{ pb: 3 }}>
      <ProductModal
        open={isProductModalOpen}
        onClose={handleCloseProductModal}
        type="create"
        vendor={vendor}
      />
      <VendorModal
        open={isVendorModalOpen}
        onClose={handleCloseVendorModal}
        type="rename"
        defaultName={name}
        vendor={vendor}
      />
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <VendorAvatar vendor={vendor} />
        <VendorMenu
          onAddProduct={handleAddProduct}
          onRenameVendor={handleRenameVendor}
          onArchiveVendor={handleArchiveVendor}
        />
      </Box>

      <List>
        {activeProducts.length === 0 && archivedProducts.length === 0 ? (
          <EmptyVendor onAddProduct={handleAddProduct} />
        ) : (
          <PopulatedVendor
            activeProducts={activeProducts}
            archivedProducts={archivedProducts}
            name={name}
            stores={stores}
            onUnarchiveProduct={handleUnarchiveProduct}
          />
        )}
      </List>
    </Box>
  );
};

export default VendorList;
