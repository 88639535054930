import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGOUT_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  GET_USER_LOAD,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from '../actions/auth';

const defaultState = {
  user: null,
  userName: null,
  isAuthenticated: false,
  isAuthenticating: false,
  statusText: null,
  isRegistering: false,
  isRegistered: false,
  registerStatusText: null,
  isDeleting: false,
  isDeleted: false,
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        statusText: null,
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: action.payload.user,
        access: action.payload.access,
        statusText: 'You have been successfully logged in.',
      }
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        user: null,
        userName: null,
        statusText: action.payload.statusText,
      }
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        isRegistering: false,
        isRegistered: false,
        user: null,
        userName: null,
        statusText: null,
        registerStatusText: null,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isRegistering: false,
        isRegistered: true,
        user: action.payload.user,
        userName: 'TBD fix me',
        registerStatusText: 'You have successfully registered!',
      }
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        isRegistering: true,
        registerStatusText: null,
      }
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isRegistering: false,
        user: null,
        userName: null,
        registerStatusText: action.payload.statusText,
      }
    case GET_USER_LOAD:
      return {
        ...state,
        isAuthenticating: true,
        statusText: null,
        registerStatusText: null,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: action.payload.user,
        access: action.payload.access,
      }
    case GET_USER_FAIL:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        user: null,
        userName: null,
        statusText: null,
        registerStatusText: null,
      }
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isAuthenticating: false,
        statusText: null,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        user: null,
        userName: null,
        isDeleting: true,
        isDeleted: true,
        statusText: 'You have successfully deleted your account.',
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isDeleting: true,
        isDeleted: false,
        statusText: 'There was an error in deleting your account. Please try again.',
      }
    default:
      return state;
  }
}
