import React, { Component } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import RegisterSuccess from "./RegisterSuccess";
import Signup from "./Signup";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0 };
  }

  getCurrentElement() {
    switch (this.state.step) {
      case 0:
        return <Signup onFinish={this.nextStep} />;
      case 1:
        return <RegisterSuccess />;
      default:
        return <Signup onFinish={this.nextStep} />;
    }
  }

  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  prevStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="landing-page"
      >
        {this.getCurrentElement()}
      </Grid>
    );
  }
}

export default Registration;
