import {
  STORES_REQUEST,
  STORES_FAILURE,
  STORES_SUCCESS,
} from '../actions/stores';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case STORES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload.stores,
        isSuccess: true,
        isFetching: false,
        statusText: action.payload.statusText,
      };
    case STORES_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        statusText: action.payload.statusText,
      };
    default:
      return state;
  }
}
