import React, { useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import VendorModal from "./VendorModal";

const CreateVendorButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateVendor = async (vendor) => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Create vendor
      </Button>
      <VendorModal
        open={isModalOpen}
        onClose={handleCreateVendor}
        type="create"
      />
    </>
  );
};

export default CreateVendorButton;
