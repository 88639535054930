import { fetchStoresList } from '../utils/apiRequests';

const namespace = 'stores'
export const STORES_REQUEST = `${namespace}/STORES_REQUEST`;
export const STORES_FAILURE = `${namespace}/STORES_FAILURE`;
export const STORES_SUCCESS = `${namespace}/STORES_SUCCESS`;

const DEFAULT_ERROR = {
  response: {
    status: 500,
    statusText: 'Something went wrong. Try again.',
  },
};

export function storesRequest() {
  return {
    type: STORES_REQUEST,
  };
}

export function storesFailure(error = DEFAULT_ERROR) {
  return {
    type: STORES_FAILURE,
    payload: {
      statusText: error.response.statusText,
    },
  };
}

export function storesSuccess(stores) {
  return {
    type: STORES_SUCCESS,
    payload: {
      stores,
      status: 200,
      statusText: 'Stores list retrieved.',
    },
  };
}

export function getStoresList() {
  return (dispatch) => {
    dispatch(storesRequest());
    return fetchStoresList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(storesSuccess(response.data));
        } else {
          dispatch(storesFailure());
        }
      })
      .catch((e) => {
        dispatch(storesFailure());
      });
  };
}
