import React, { Suspense, useEffect } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";

import { tryGetUser } from "../../actions/auth";
import Landing from "../Landing";
import Registration from "../Registration";
import Navigation from "../Navigation";
import OutApp from "../Out";
import Dashboard from "../Dashboard";

import Logo from "../../assets/snail.png";
import { VERSION } from "../../constants";

import "./style.scss";

const queryClient = new QueryClient();

const App = ({ auth, dispatch }) => {
  const { isAuthenticated } = auth;
  const Home = auth.isAuthenticated ? Dashboard : Landing;

  // get user when mounting
  useEffect(() => {
    dispatch(tryGetUser());
  }, [dispatch]);

  const themeOptions = {
    palette: {
      mode: "light",
      primary: {
        main: "#e9724c",
      },
      secondary: {
        main: "#255f85",
      },
    },
  };
  const theme = createTheme(themeOptions);

  return (
    <Suspense>
      <Router>
        <Helmet>
          <title>PARticle::In</title>
          <meta name="description" content="PARticle::In" />
        </Helmet>
        {isAuthenticated && <Navigation />}
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Grid
              container
              className={`app-container ${isAuthenticated ? "" : "landing"}`}
            >
              <Switch>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/registration" component={Registration} />
                <Route path="/out/:store_id" component={OutApp} />
                <Route exact path="/" component={Home} />
              </Switch>
              <Grid
                container
                xs={12}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                sx={{ mb: 4 }}
              >
                <Grid xs={12}>
                  <img
                    src={Logo}
                    alt="Logo"
                    className="logo"
                    style={{ width: "100px" }}
                  />
                </Grid>
                <Grid xs={12}>
                  <Typography variant="caption">{VERSION}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </QueryClientProvider>
        </ThemeProvider>
      </Router>
    </Suspense>
  );
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect((state) => ({ auth: state.auth }))(App);
