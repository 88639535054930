import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import Login from "../Login";

import "./styles.scss";

function Landing() {
  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="landing-page-container"
      >
        <Grid xs={12} sm={8} md={6}>
          <Typography variant="h3">
            🐧 Welcome to <code>PARticle In</code>
          </Typography>
          <Login />
          <Box pt={2}>
            <Link to="/registration">
              <Button variant="outlined" fullWidth>
                Register
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Landing;
