import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import { Alert, Button, Container } from "@mui/material";
import { getStoresVendorsProducts } from "../../utils/apiRequests";

import { getQueryParam } from "./url";
import Out from "./Out";
import LeftOvers from "./LeftOvers";
import config from "./config";
import Header from "./Header";

const OutApp = (props) => {
  const { store_id: storeId } = useParams();
  const {
    isLoading: isLoadingVendors,
    isError: isErrorVendors,
    data: vendors,
  } = useQuery(["vendors", storeId], () => getStoresVendorsProducts(storeId));

  const configTemplate = {
    apiKey: getQueryParam("key"),
    clientId: getQueryParam("client"),
    spreadSheetId: getQueryParam("sheetId"),
    ...config,
  };

  let initialAlert = "🥳 Loading...hang on!";
  for (let key in configTemplate) {
    if (!configTemplate[key]) {
      initialAlert = "🤨 URL incorrect (fix the query args)";
    }
  }

  const [page, setPage] = useState("out");
  const [apiConfig, setApiConfig] = useState({ ...configTemplate });

  return (
    <Container fixed sx={{ mb: 5 }}>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Header />
        </Grid>
        {isLoadingVendors && (
          <Grid>
            <Alert severity="info">Loading Vendors...</Alert>
          </Grid>
        )}
        {isErrorVendors && (
          <Grid>
            <Alert severity="error">Error loading Vendors</Alert>
          </Grid>
        )}
        <Grid xs={12}>
          <Grid container spacing={2}>
            <Grid>
              <Button disabled={page === "out"} onClick={() => setPage("out")}>
                Record Out
              </Button>
            </Grid>
            <Grid>
              <Button
                disabled={page === "leftovers"}
                onClick={() => setPage("leftovers")}
              >
                Record Leftovers
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {((!props.inventory.isPostingOut && !props.inventory.isSuccessOut) ||
          (!props.inventory.isPostingReset &&
            !props.inventory.isSuccessReset)) && (
          <Grid xs={12}>
            <Grid>
              <Alert severity="error">
                Something went wrong on the Particle API.
              </Alert>
            </Grid>
          </Grid>
        )}
        {page === "out" && <Out apiConfig={apiConfig} vendors={vendors} />}
        {page === "leftovers" && (
          <LeftOvers apiConfig={apiConfig} vendors={vendors} />
        )}
      </Grid>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    inventory: state.inventory,
  };
}

export default connect(mapStateToProps)(OutApp);
