import React from "react";
import { Paper, Typography, Button } from "@mui/material";

const EmptyVendor = ({ onAddProduct }) => (
  <Paper sx={{ p: 3, textAlign: "center" }}>
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      No products for this vendor yet.
    </Typography>
    <Button onClick={onAddProduct}>Add Product</Button>
  </Paper>
);

export default EmptyVendor;
