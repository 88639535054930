import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Alert, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";

import OutButton from "./OutButton";
import { VendorAvatar } from "../VendorAvatar";
import { getLeftovers } from "../../utils/apiRequests";

const Out = ({ apiConfig, vendors }) => {
  const { data: outTimes } = useQuery("leftovers", () =>
    getLeftovers(apiConfig.spreadSheetId)
  );

  if (vendors) {
    return (
      <Grid container>
        <Grid xs={12}>
          <Alert severity="info">
            Click on a button to indicate pars are out. Click again to undo 👈🏻.
          </Alert>
        </Grid>
        {vendors.map((vendor) => {
          return (
            <Grid xs={12} key={`${vendor.id}-out`}>
              <Grid xs={12}>
                <VendorAvatar vendor={vendor} />
              </Grid>
              <Grid container spacing={1}>
                {vendor.products.map((item) => {
                  return (
                    <Grid xs={12} key={`${vendor.id}-${item.id}-outl`}>
                      <OutButton
                        outTimes={outTimes}
                        apiConfig={apiConfig}
                        item={item}
                        key={`${vendor.id}-${item.id}`}
                      >
                        {item.name}
                      </OutButton>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return <CircularProgress />;
  }
};

export default Out;
