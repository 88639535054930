import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArchiveIcon from "@mui/icons-material/Archive";

import VendorProduct from "./VendorProduct";
import ArchivedProductCard from "./ArchivedProductCard";

const PopulatedVendor = ({
  activeProducts,
  archivedProducts,
  name,
  stores,
  onUnarchiveProduct,
}) => (
  <>
    {activeProducts.map((product) => (
      <VendorProduct key={product.id} product={product} stores={stores} />
    ))}
    {archivedProducts.length > 0 && (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="archived-products-content"
          id="archived-products-header"
        >
          <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
            <ArchiveIcon fontSize="small" /> Archived Products for {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {archivedProducts.map((product) => (
              <Grid item key={product.id} xs={12} sm={6} md={4}>
                <ArchivedProductCard
                  product={product}
                  onUnarchive={onUnarchiveProduct}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )}
  </>
);

export default PopulatedVendor;
