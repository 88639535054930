import React from "react";
import { Typography } from "@mui/material";

const Header = () => {
  return (
    <Typography sx={{ my: 3 }} variant="h3">
      Out
    </Typography>
  );
};

export default Header;
