import {
  INVENTORY_RESET_ALL_REQUEST,
  INVENTORY_RESET_ALL_FAILURE,
  INVENTORY_RESET_ALL_SUCCESS,
  INVENTORY_SEARCH_ITEMS_REQUEST,
  INVENTORY_SEARCH_ITEMS_FAILURE,
  INVENTORY_SEARCH_ITEMS_SUCCESS,
} from "../actions/inventory";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case INVENTORY_RESET_ALL_REQUEST:
      return {
        ...state,
        isPostingOut: true,
      };
    case INVENTORY_RESET_ALL_SUCCESS:
      return {
        ...state,
        isSuccessReset: true,
        isPostingReset: false,
        statusText: action.payload.statusText,
      };
    case INVENTORY_RESET_ALL_FAILURE:
      return {
        ...state,
        isSuccessReset: false,
        isPostingReset: false,
        statusText: action.payload.statusText,
      };
    case INVENTORY_SEARCH_ITEMS_REQUEST:
      return {
        ...state,
        isGettingAutocomplete: true,
      };
    case INVENTORY_SEARCH_ITEMS_SUCCESS:
      return {
        ...state,
        autocompleteSearchItems: action.payload.items,
        autocompleteData: action.payload.autocompleteData,
        isGettingAutocomplete: false,
      };
    case INVENTORY_SEARCH_ITEMS_FAILURE:
      return {
        ...state,
        autocompleteData: {},
        isGettingAutocomplete: false,
        statusText: action.payload.statusText,
      };
    default:
      return {
        vendors: {},
        isPostingOut: false,
        isSuccessOut: true,
        isSuccessReset: false,
        isPostingReset: true,
        ...state,
      };
  }
}
