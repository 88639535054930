import React from "react";
import { Chip, Avatar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CancelIcon from "@mui/icons-material/Cancel";
import { stringAvatar } from "./utils";

const SquareItemChip = ({ item, onDelete }) => {
  const handleDelete = () => {
    onDelete(item);
  };

  return (
    <Grid key={item.item_id} spacing={2}>
      <Chip
        label={item.name}
        color={item.name.includes("not found") ? "error" : "default"}
        avatar={
          <Avatar {...stringAvatar(item.name.split(" | ")[0])}>
            {item.name[0]}
          </Avatar>
        }
        onDelete={handleDelete}
        deleteIcon={<CancelIcon />}
      />
    </Grid>
  );
};

export default SquareItemChip;
