const IGNORE_INVENTORY = ["Egg Sandwich"];

function getBackgroundColor(stringInput) {
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 65%, 80%)`;
}

export function stringAvatar(name) {
  // replace the ignore inventory item parts with empty string
  const modName = IGNORE_INVENTORY.reduce((acc, item) => {
    return acc.replace(item, "");
  }, name);

  return {
    sx: {
      bgcolor: getBackgroundColor(modName),
    },
    children: `${name[0]}`,
  };
}
