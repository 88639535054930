import { combineReducers } from "redux";
import auth from "./auth";
import resetPassword from "./resetPassword";
import stores from "./stores";
import inventory from "./inventory";

export default combineReducers({
  auth,
  resetPassword,
  stores,
  inventory,
});
