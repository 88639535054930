import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { registerUser } from "../../actions/auth";
import { Alert, Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      accessToken: "",
      email: "",
      errorText: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.auth.isRegistered && nextProps.auth.isRegistered) {
      this.props.onFinish();
    }
  }

  handleInputChange = (e, inputField) => {
    this.setState({
      [inputField]: e.target.value,
    });
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const { password, confirmPassword, accessToken, email } = this.state;

    // very minimal validation. More can be done similar to the report crisis forms.
    const canProceed =
      accessToken.length > 0 &&
      password.length > 0 &&
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ) &&
      password === confirmPassword;

    if (canProceed) {
      this.props.dispatch(registerUser(email, password, accessToken));
      this.setState({
        errorText: "",
      });
    } else {
      this.setState({
        errorText: "All fields must be filled out. Correct invalid input.",
      });
    }
  };

  render() {
    return (
      <Grid container xs={12} sm={8} md={6} spacing={3}>
        <Grid xs={12}>
          <Typography variant="h5" component="h1">
            Contact your administrator for the access token.
          </Typography>
        </Grid>
        {this.props.auth.registerStatusText && (
          <Grid xs={12}>
            <Alert severity="warning">
              Try again. {this.props.auth.registerStatusText}
            </Alert>
          </Grid>
        )}
        {this.state.errorText && (
          <Grid xs={12}>
            <Alert severity="error">{this.state.errorText}</Alert>
          </Grid>
        )}
        <Grid xs={12}>
          <TextField
            fullWidth
            label="Access Token"
            value={this.state.accessToken}
            onChange={(e) => this.handleInputChange(e, "accessToken")}
            name="accessToken"
            id="accessToken"
            placeholder="Access Token"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label="email"
            value={this.state.email}
            onChange={(e) => this.handleInputChange(e, "email")}
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            fullWidth
            label="Password"
            value={this.state.password}
            onChange={(e) => this.handleInputChange(e, "password")}
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label="Confirm Password"
            value={this.state.confirmPassword}
            onChange={(e) => this.handleInputChange(e, "confirmPassword")}
            type="password"
            name="password"
            id="passwordConfirm"
            placeholder="Enter your password"
          />
        </Grid>
        <Grid xs={12}>
          <Button fullWidth variant="contained" onClick={this.saveAndContinue}>
            Create Account
          </Button>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

Signup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Signup);
